<template>
  <div class="EmployeesData">
    <!-- 导师后台 - 员工数据 -->
    <div style="background: #fff">
      <div
        style="
          height: 49px;
          box-sizing: border-box;
          padding: 0 12px;
          display: flex;
          justify-content: center;
          align-items: center;
        "
      >
        <div
          class="input-date"
          style="
            display: flex;
            align-items: center;
            justify-content: space-between;
            width: 100%;
          "
        >
          <div style="font-size: 12px; color: #2f2f2f">筛选</div>
          <el-date-picker
            v-model="employeesDataSearch.betgin_time"
            value-format="yyyy-MM-dd HH:mm"
            type="date"
            placeholder="选择开始日期"
            style="width: 105px"
          ></el-date-picker>
          <el-date-picker
            v-model="employeesDataSearch.end_time"
            value-format="yyyy-MM-dd HH:mm"
            type="date"
            placeholder="选择结束日期"
            style="margin-left: 7px; width: 105px"
          ></el-date-picker>
          <div
            @click="handleSearchBtn"
            style="
              width: 64px;
              font-size: 14px;
              height: 33px;
              line-height: 33px;
              text-align: center;
              border-radius: 3px;
              background: #4372fc;
              color: #fff;
            "
          >
            搜索
          </div>
        </div>
      </div>

      <div
        style="
          padding: 14px 12px;
          height: 33px;
          display: flex;
          align-items: center;
          justify-content: space-between;
        "
      >
        <div style="display: flex; align-items: center">
          <div style="font-size: 12px">每天重新统计时间：</div>
          <el-time-picker
            class="small-input-date"
            v-model="employeesDataSearch.find_time"
            value-format="hh:mm:ss"
            :picker-options="{ selectableRange: '00:00:00 - 23:59:59' }"
          ></el-time-picker>
        </div>

        <div
          @click="handleSearchBtn"
          style="
            width: 64px;
            font-size: 14px;
            height: 33px;
            line-height: 33px;
            text-align: center;
            border-radius: 3px;
            background: #4372fc;
            color: #fff;
          "
        >
          确定
        </div>
      </div>

      <div
        style="
          color: #e94242;
          font-size: 12px;
          font-weight: 400;
          padding: 0 12px 16px;
        "
      >
        注：从选择时间开始重新从0开始统计每日数据
      </div>

      <div style="margin: 0 7px; padding-bottom: 10px">
        <table
          border="1"
          cellspacing="0"
          style="font-weight: 400; font-size: 12px; width: 100%"
        >
          <thead style="color: #fff; background: #4372fc">
            <!-- <tr>
              <th>排名</th>
              <th>员工</th>
              <th>直接成交</th>
              <th>间接成交</th>
              <th>当日和总邀请</th>
            </tr> -->
            <th>排名</th>
            <th>员工</th>
            <th>总成交</th>
            <th>一级</th>
            <th>二级</th>
            <th>三级及所有</th>
          </thead>
          <tbody style="text-align: center; color: #2e2e2e">
            <tr v-for="(item, index) in employeesDataList" :key="index">
              <!-- 排名 -->
              <td>{{ index + 1 }}</td>
              <!-- 员工 -->
              <td>
                <div>{{ item.realname }}</div>
              </td>
              <!-- 总成交 -->
              <td>
                <div>
                  <span style="color: #1ec44d">{{
                    (item.my_order || 0) +
                    (item.team_order || 0) +
                    (item.other_order || 0)
                  }}</span>
                  <span>单</span>
                </div>
                <!-- <div style="color: #f27623">
                  {{
                    (item.my_order_amount || 0) +
                    (item.team_order_amount || 0) +
                    (item.other_order_amount || 0)
                  }}元
                </div> -->
              </td>
              <!-- 一级 -->
              <td>
                <div>
                  <span style="color: #1ec44d">{{ item.my_order || 0 }}</span>
                  <span>单</span>
                </div>
                <!-- <div style="color: #f27623">
                  {{ item.my_order_amount || 0 }}元
                </div> -->
              </td>
              <!-- 二级 -->
              <td>
                <div>{{ item.team_order || 0 }}单</div>
                <!-- <div>{{ item.team_order_amount || 0 }}次</div> -->
              </td>
              <!-- 三级及所有 -->
              <td>
                <div>
                  <span style="color: #1ec44d">{{
                    item.other_order || 0
                  }}</span>
                  <span>单</span>
                </div>
                <!-- <div style="color: #f27623">
                  {{ item.other_order_amount || 0 }}元
                </div> -->
              </td>
              <!-- -->
              <!-- <td>
                <div>{{ item.today_share }}次</div>
                <div>{{ item.total_share }}次</div>
              </td> -->
            </tr>
          </tbody>
        </table>

        <div
          style="
            display: flex;
            align-items: center;
            justify-content: space-between;
            margin-top: 10px;
          "
        >
          <div
            @click="handleChangePage(0)"
            style="
              background: #f4f4f4;
              font-size: 12px;
              color: #2e2e2e;
              padding: 5px 15px;
              border-radius: 4px;
              text-align: center;
            "
          >
            上一页
          </div>
          <div
            @click="handleChangePage(1)"
            style="
              background: #f4f4f4;
              font-size: 12px;
              color: #2e2e2e;
              padding: 5px 15px;
              border-radius: 4px;
              text-align: center;
            "
          >
            下一页
          </div>
        </div>
      </div>

      <!-- <div style="color: #E94242; font-size: 12px; padding: 11px 25px; font-weight: 400;">不显示注：直接就是一级成交，间接就是二级及以后的</div> -->
    </div>
  </div>
</template>

<script>
export default {
  name: "EmployeesData",
  components: {

  },
  data() {
    return {
      // 员工数据的分页
      employeesDataPaging: {
        currentPageIndex: 1,
        total: 0,
      },
      // 员工数据的搜索条件
      employeesDataSearch: {
        betgin_time: '',
        end_time: '',
        find_time: '00:00:00',
      },
      // 活动id
      id: "",
      // 员工列表
      employeesDataList: [],
    }
  },
  mounted() {
    this.id = parseInt(this.$route.query.id);
    this.getEmployeesData(this.id)
  },
  methods: {
    /** 处理点击搜索按钮 */
    handleSearchBtn() {
      this.employeesDataPaging.currentPageIndex = 1;
      this.getEmployeesData(this.id);
    },

    /** 切换页码 */
    handleChangePage(type) {
      if (type === 0 && this.employeesDataPaging.currentPageIndex > 1) {
        this.employeesDataPaging.currentPageIndex--;
        this.getEmployeesData(this.id);
      }
      else if (type === 1 && this.employeesDataPaging.currentPageIndex * 15 < this.employeesDataPaging.total) {
        this.employeesDataPaging.currentPageIndex++;
        this.getEmployeesData(this.id);
      }
    },

    /** 获取员工数据 */
    getEmployeesData(id) {
      this.$axios.post("/events/getByTeamList", {
        page: this.employeesDataPaging.currentPageIndex,
        event_id: id,
        ...this.employeesDataSearch,
      }).then(res => {
        this.employeesDataList = res.data;
        this.employeesDataPaging.total = res.total;
      });
    },
  }
};
</script>

<style scoped lang="scss">
.EmployeesData {
  background: #f5f5f5;
  min-height: 100vh;
  height: 100%;
  font-weight: bold;
}

table {
  border-collapse: collapse;
  border-spacing: 0 !important;
  thead {
    border-color: transparent !important;
  }
  tbody {
    th,
    td {
      border: 0.5px solid #cccccc;
    }
  }
}

.input-date {
  ::v-deep {
    input {
      padding: 0 5px;
      font-size: 12px;
      text-align: center;
      height: 33px;
      line-height: 33px;
    }
    .el-input__prefix {
      display: none;
    }
  }
}

::v-deep .small-input-date {
  width: 100px;
  text-align: center;
  .el-input__prefix {
    display: none;
  }
  input {
    padding: 0 5px;
    height: 33px;
    text-align: center;
    line-height: 33px;
  }
}
</style>
